:root {
  --title-color: var(--color-text-dark);
  --subtitle-color: var(--color-text-medium);
  --muted-color: var(--color-text-light);
}

.list {
  margin-left: auto;
  margin-right: auto;
}

.list a {
  text-decoration: none;
}

.header {
  composes: mt4 mb2 from "style";
  flex-direction: row;
  display: flex;
  color: var(--title-color);
  font-size: 24px;
  min-height: 48px;
}

.headerBody {
  composes: border-bottom from "style";
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  border-color: var(--color-brand);
}

.headerLink {
  composes: ml2 from "style";
  flex-shrink: 0;
  color: var(--color-brand);
  font-size: 14px;
}

.headerButton {
  composes: ml1 from "style";
  align-items: center;
  display: flex;
  font-size: 14px;
}

.empty {
  width: 100%;
  justify-content: center;
  display: flex;
  padding-top: 75px;
}

.item {
  position: relative;
  align-items: center;
  display: flex;
}

.itemBody {
  max-width: 100%;
  flex: 1 0 auto;
}

.itemTitle {
  composes: text-bold from "style";
  max-width: 100%;
  overflow: hidden;
}

.itemSubtitle {
  color: var(--subtitle-color);
  max-width: 600px;
  font-size: 14px;
}

.itemSubtitleLight {
  composes: text-light from "style";
  font-size: 14px;
}

.itemSubtitleBold {
  color: var(--title-color);
}

.icons {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.leftIcons {
  composes: mr2 from "style";
  align-self: flex-start;
  flex-shrink: 0;
  display: flex;
  composes: icons;
}

.rightIcons {
  composes: icons;
}

.itemIcons {
  composes: leftIcons;
  padding-top: 4px;
}

.extraIcons {
  composes: icons;
  top: 0;
  position: absolute;
  height: 100%;
  right: -40px;
}

/* hack fix for IE 11 which was hiding the archive icon */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .extraIcons {
    composes: icons;
  }
}

.icon {
  position: relative;
  color: var(--muted-color);
}

.item .icon {
  visibility: hidden;
}

.item:hover .icon {
  visibility: visible;
}

.icon:hover {
  color: var(--color-brand);
}

/* ITEM CHECKBOX */
.itemCheckbox {
  composes: icon;
  display: none;
  visibility: visible !important;
  margin-left: 10px;
}

.item:hover .itemCheckbox,
.item.selected .itemCheckbox {
  display: inline;
}

.item.selected .itemCheckbox {
  color: var(--color-brand);
}

/* ITEM ICON */
.itemIcon {
  composes: icon;
  visibility: visible !important;
  position: relative;
}

.item:hover .itemIcon,
.item.selected .itemIcon {
  display: none;
}

/* CHART ICON */
.chartIcon {
  composes: icon;
  visibility: visible !important;
  position: relative;
}

/* ACTION ICONS */
.tagIcon,
.favoriteIcon,
.archiveIcon {
  composes: icon;
  composes: mx1 from "style";
}

.trigger {
  line-height: 0;
}
