.colorIcon {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 3px;
}

.emojiIcon {
  text-align: center;
  font-size: 20px;
}

.icon {
  composes: transition-color from "style";
  color: currentColor;
}
